@import 'layouts/public';
@import 'layouts/private';

@import 'mixins/fontFace';

@include font-face("Roboto", "../../fonts/Roboto/Roboto-Black", 900, normal, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-Bold", 800, normal, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-Medium", 600, normal, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-Regular", 400, normal, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-Light", 200, normal, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-Thin", 100, normal, ttf);

@include font-face("Roboto", "../../fonts/Roboto/Roboto-BlackItalic", 900, italic, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-BoldItalic", 800, italic, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-MediumItalic", 600, italic, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-Italic", 400, italic, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-LightItalic", 200, italic, ttf);
@include font-face("Roboto", "../../fonts/Roboto/Roboto-ThinItalic", 100, italic, ttf);

@include font-face("Baloo Paaji", "../../fonts/BalooPaaji2/BalooPaaji2-ExtraBold", 900, normal, ttf);
@include font-face("Baloo Paaji", "../../fonts/BalooPaaji2/BalooPaaji2-Bold", 800, normal, ttf);
@include font-face("Baloo Paaji", "../../fonts/BalooPaaji2/BalooPaaji2-SemiBold", 600, normal, ttf);
@include font-face("Baloo Paaji", "../../fonts/BalooPaaji2/BalooPaaji2-Medium", 500, normal, ttf);
@include font-face("Baloo Paaji", "../../fonts/BalooPaaji2/BalooPaaji2-Regular", 400, normal, ttf);

$font-family-body: "Roboto";
$font-family-title: "Baloo Paaji";

* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    font-family: $font-family-body !important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-family-title !important;
}

.no-decoration-link { text-decoration: none; }

.font-white { color: #fff; }
.font-warning { color: rgb(102, 60, 0); }

.bold { font-weight: bold; }

.ck-editor__top { display: none; }

.ck-content { height: 300px; }
  
.ck-editor__main { font-size: 16px; }
  
.ck.ck-editor__main > .ck-editor__editable { 
    background-color: transparent !important; 
}
  
.ck.ck-editor__editable_inline { 
    padding: 0px !important; 
    border: none !important; 
    border-bottom: 1px solid rgba(0, 0, 0, 0.80) !important;
}

@media print {
    .no-print, .no-print * {
        display: none !important;
    }

    .ccb--hidden {
        display: none !important;
    }
}

.MuiTableCell-stickyHeader {
    top: 7% !important;
}

#valorPrevisto {
    font-size: 1.5em;
    font-weight: 900 !important;
    color: darkblue !important;
}

a:link, 
a:visited, 
a:hover, 
a:active { text-decoration: none; }